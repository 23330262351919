html, body, #root {
  margin: 0px;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Calibri',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: url("../public/bg.png"); */
  /* position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}